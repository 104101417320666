@import "../dynamic/colors";

@each $color, $value in $colors {
  .text_#{"" + $color} {
    color: $value !important;
  }
  .background_#{"" + $color} {
    background-color: $value;
  }
  .border_#{"" + $color} {
    border: 1px solid $value !important;
  }
  .border_top_color_#{"" + $color} {
    border-top-color: $value !important;
  }
  .border_left_color_#{"" + $color} {
    border-left-color: $value !important;
  }
  .borderColor_#{"" + $color} {
    border-color: $value;
  }
  .gradient_#{"" + $color} {
    background: linear-gradient(180deg, $color_white 0%, $value 100%);
  }
}

@import "../../styles/dynamic/all.scss";

.new_loader_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 999;
  width: 100%;
}

.loader {
  border: 4px solid #d6d6d6;
  /* Light grey */
  border-top: 4px solid #94a3cd;
  /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  flex: unset !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader_title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 20px;
  padding: 0 10px;
}

.pageLoader_container {
  position: relative;
  .loading_container {
    background-color: #fafafa;
    z-index: 10;
    zoom: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@import '../dynamic/all.scss';

.form_control {
  width: 100%;
  position: relative;
  // margin-bottom: 24px;
  @include font_gt_walsheim;
  .lbl {
    color: $color_primary;
    font-size: 14px;
    margin-bottom: $half_spacing;
    @include font_gt_walsheim;
    @include breakpoint(gtxs) {
      font-size: 16px;
      margin-bottom: 12px;
      @include font_gt_walsheim;
    }
  }
  .text_wrap {
    position: relative;
    input:focus {
      outline: none;
    }
    input.form_input:-webkit-autofill {
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
    .form_input {
      height: 36px;
      border: 2px solid $color_primary;
      box-sizing: border-box;
      border-radius: 10px;
      width: 100%;
      padding: 0 85px 0 16px;
      @include font_gt_walsheim;
      @include breakpoint(gtxs) {
        height: 40px;
      }
    }
    .input_field_icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      margin-right: 20px;
      &.input_field_with_text {
        width: fit-content;
        height: 16px;
        cursor: pointer;
        font-size: 14px;
        text-align: right;
        margin-right: 12px;
        text-decoration: underline;
      }
    }
  }
  .info_text {
    font-size: 14px;
    padding-top: 4px;
    color: $color_gray_light_4;
    @include font_gt_walsheim;
  }
  .error_lbl {
    display: none;
  }
  &.error {
    .form_input {
      border: 2px solid $color_error;
    }
    .error_lbl {
      display: block;
      position: absolute;
      top: -4px;
      right: 0;
      font-size: 14px;
      color: $color_error;
      @include font_gt_walsheim;
      .error_icon {
        vertical-align: -1px;
      }
      @include breakpoint(gtxs) {
        top: 0;
      }
    }
  }
}

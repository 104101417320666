.loginSite {
  background-color: #f4f4f4;
  width: 100vw;
  height: 100vh;

  .login-form {
    min-height: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px #e4e4e4 solid;
    border-radius: 3%;
    box-shadow: 0px 8px 16px rgba(44, 44, 44, 0.1);
    background-color: #fff;

    &.floater {
      min-height: 230px;
    }

    div:first-child {
      text-align: center;
    }

    .logo_wrapper {
      border-bottom: 1px #e4e4e4 solid;

      img {
        margin-left: auto;
        margin-right: auto;
        width: 120px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    .title_wrapper {
      margin: 20px 20px;
      border-bottom: 1px #e4e4e4 solid;

      span {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 50px;
        color: #2C2C2C;
      }
    }

    .input_wrapper {
      width: 100%;
      padding: 0 20px;

      .error_wrapper {
        transform: translate(0px, -6px);
        display: flex;
        justify-content: center;
        z-index: 25;
        width: 100%;

        .error-box {
          width: 95vw;
          min-width: 350px;
          max-width: 480px;
          border-radius: 12px;
          border: 1px solid #d5410c;
          background-color: #fce0d5;
          color: #d5410c;
          font-size: 0.875rem;
          line-height: 18px;
          padding: 13px 16px;
        }

        .error-top {
          position: relative;
          font-weight: 700;

          button {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: transparent;
            border: none;
            color: #d5410c;
          }
        }

        .error-content {
          font-weight: 400;
        }
      }

      .login-field-wrapper {
        position: relative;

        span {
          display: flex;
          font-size: 1rem;
          font-weight: bold;
          line-height: 20px;
          color: #2C2C2C;
          margin: 15px 0 10px 0px;
        }

        input {
          width: 100%;
          height: 40px;
          border: 1px solid #e4e4e4;
          border-radius: 10px;
          font-size: 1rem;
          padding-left: 12px;
        }

        .eye {
          position: absolute;
          top: 42px;
          right: 8px;
          z-index: 2;
          cursor: pointer;
        }

        .error-info {
          width: 100%;
          line-height: 18px;
          font-size: 0.875rem;
          color: #a94442;
          text-align: left;
          margin-top: 5px;
        }
      }

      .iss-login-button {
        position: relative;
        width: 100%;
        left: 0px;
        margin-top: 15px;
        margin-bottom: 35px;
      }

      .forgotPWD_wrapper {
        text-align: center;
        margin-bottom: 30px;

        span {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 20px;
          color: #2C2C2C;
          cursor: pointer;
        }
      }
    }
  }
}
// Hide at Breakpoint

@import '../dynamic/media_queries.scss';

@include breakpoint(lg) {
  .hide_lg {
    display: none !important;
  }
}
@include breakpoint(md) {
  .hide_md {
    display: none !important;
  }
}
@include breakpoint(gtxs) {
  .hide_sm {
    display: none !important;
  }
}
@include breakpoint(sm_tablet) {
  .hide_sm_tablet {
    display: none !important;
  }
}
@include breakpoint(sm_phone) {
  .hide_sm_phone {
    display: none !important;
  }
}

@import "../../styles/dynamic/all.scss";

.cr-footer {
  background-color: #E4E4E4;

  .navbar {
    width: 100%;
    padding: 0px;

    .navbar-nav {
      width: 100%;

      .nav-item {
        cursor: pointer;
        height: 50px;
        width: 100%;
        padding-top: 20px;
        font-weight: 700;
        font-size: 0.8rem;
        position: relative;
        padding-left: 20px;
        color: #2c2c2c;

        svg {
          float: right;
          margin-right: 15px;
          margin-top: -4px;
        }

        &::after {
          content: " ";
          left: 15px;
          position: absolute;
          bottom: 0px;
          background-color: #e4e4e4;
          height: 1px;
          width: calc(100% - 30px);
        }

        &.sub-footer {
          padding-left: 40px;

          a {
            all: unset;
          }
        }
      }
    }
  }

  .media {
    text-align: center;
    padding: 24px 0 22px;

    span {
      color: #2C2C2C;
      font-size: 0.875rem;
      line-height: 16px;
      font-weight: 600;
    }

    .logo {
      padding-top: 14px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 36px;
        height: auto;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }

  .copyright {
    background-color: #D6D6D6;
    text-align: center;
    padding: 23px 0;

    p {
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 18px;
      padding-bottom: 18px;
    }

    img {
      margin: 0 8px;
      cursor: pointer;
    }
  }
}

.cr-footer-desktop {
  background-color: #D6D6D6;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  float: none;

  .navbar {
    width: 100%;
    padding: 0px;

    .navbar-nav {
      width: 100%;

      .nav-item {
        cursor: pointer;
        height: 50px;
        width: 100%;
        padding-top: 20px;
        font-weight: 700;
        font-size: 0.8rem;
        position: relative;
        padding-left: 20px;
        color: #2c2c2c;

        svg {
          float: right;
          margin-right: 15px;
          margin-top: -4px;
        }

        &::after {
          content: " ";
          left: 15px;
          position: absolute;
          bottom: 0px;
          background-color: #e4e4e4;
          height: 1px;
          width: calc(100% - 30px);
        }

        &.sub-footer {
          padding-left: 40px;

          a {
            all: unset;
          }
        }
      }
    }
  }

  .right_div {
    width: 60%;

    .media {
      text-align: center;
      padding: 50px 15px 10px 15px;

      .mediaWrapper {
        width: fit-content;
        margin-left: auto;
        justify-content: center;
        align-items: center;

        span {
          color: #2C2C2C;
          font-size: 0.875rem;
          line-height: 16px;
          font-weight: 600;
        }

        .logo {
          padding-top: 5px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 36px;
            height: auto;
            margin: 0 8px;
            cursor: pointer;
          }
        }
      }
    }

    .copyright {
      background-color: #D6D6D6;
      text-align: right;
      padding: 8px 22px 90px 15px;
  
      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 19px;
        padding-bottom: 18px;
      }
  
      img {
        //margin: 0 8px;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .left_div {
    width: 40%;

    .address {
      background-color: #D6D6D6;
      text-align: left;
      padding: 23px 20px;
  
      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 19px;
        padding-bottom: 18px;
      }
    }
  }
}
@import '../fonts/font.scss';

.font_gt_walsheim {
  @include font_gt_walsheim;
}

.font_gt_walsheim_medium {
  @include font_gt_walsheim_medium;
}

.font_gt_walsheim_bold {
  @include font_gt_walsheim_bold;
}

@import "../../styles/dynamic/all.scss";

.fullpage_loader {
  background: rgba(112, 112, 112, 0.7);
  backdrop-filter: blur(10px);
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
}

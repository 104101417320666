@import '../../styles/dynamic/all';

.icon {
  display: inline-flex;
  position: relative;
  width: fit-content;

  .svg_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin scrollbar-style {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
    border: 0px;
  }
}

// Breakpoints
$breakpoint_xs: 576px;
$breakpoint_sm: 768px;
$breakpoint_md: 992px;
$breakpoint_lg: 1200px;
$breakpoint_xl: 1920px;

@mixin breakpoint($size) {
  @if $size==xs {
    @media all and (min-width: $breakpoint_xs) {
      @content;
    }
  } @else if $size==gtxs {
    @media all and (min-width: $breakpoint_sm) {
      @content;
    }
  } @else if $size==md {
    @media all and (min-width: $breakpoint_md) {
      @content;
    }
  } @else if $size==lg {
    @media all and (min-width: $breakpoint_lg) {
      @content;
    }
  } @else if $size==xl {
    @media all and (min-width: $breakpoint_xl) {
      @content;
    }
  }
}
